#spaceship{
    transition: all .5s ease;
}

.game-ship{
    width: 100vw;
    height: 100vh;
    background: #121212;
    position: fixed;
    top: 0;
    left: 0;
    top: 0;
    right: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.game-ship div{
    color: white;
    font-size: 24px;
}

@keyframes drop{
    0%{opacity: 0}
    20%{opacity: 0.5;}
    40%{opacity: 0.6;}
    60%{opacity: 0.8;}
    70%{transform: translateY(650px); opacity: 1;}
    100%{transform: translateX(-1000px) translateY(-500px); opacity: 1;}
}