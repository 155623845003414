.toolbar{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.results{
    padding: 16px 0;
}

.search-input{
    margin: 12px 12px 12px 0;
}

.dropdown-wrapper{
    margin: 12px 12px 12px 0;
}

.cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card{
    position: relative;
    background: #fff;
    overflow: hidden;
    color: black;
    margin-bottom: 20px;
    max-width: 100%;
    min-width: 100%;
    border-radius: 12px;
}

.card h4{
    margin: 0;    
    font-size: 20px;
    padding: 12px;    
    color: black;
    text-shadow: 0px 4px 0px rgb(7 200 183 / 50%);
    box-sizing: content-box;
}

.card_image{
    position: relative;
}

.badges{
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    flex-flow: column;
}

.badge{
    background: #EBD300;
    border-radius: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: black;
    font-size: 10px;
    margin: 10px 0 0 0;
    width: 80px;
    height: 30px;
    box-sizing: border-box;
    padding: 0px 10px;
    box-shadow: 3px 4px 9px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 3px 4px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 9px 0px rgba(0,0,0,0.75);
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.tags {
    color: black;
    padding: 6px 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.game-modal .tags{
    padding-left: 0;
    padding-top: 12px;
}

.game-modal .tags .tag{
    font-size: 14px;
}

.tags .tag{
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.tags .tag svg{
    margin-right: 2px;
}


.card_content{
    height: 145px;
}

.card_bottom{
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background: #07C8B7;
    transition: all .3s ease-in-out;
}

.card_bottom:hover{
    color: black;
    background: #3dfded;
}

.game-modal{
    position: fixed;
    background-color: transparent;
    width: 90vw;
    height: 90vh;
    overflow-y: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 9px 5px rgba(7, 200, 183, 0.5);
    -webkit-box-shadow: 0px 1px 9px 5px rgba(7, 200, 183, 0.5);
    -moz-box-shadow: 0px 1px 9px 5px rgba(7, 200, 183, 0.5);
    z-index: 9999;
}

.game-modal .title{
    margin-bottom: 20px;
}

.game-modal-content{
    position: relative;
    background-color: black;
    color: white;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    border-radius: 8px;
    min-height: 100%;
}

.game-modal-content .close-icon{
    fill: #07C8B7;
    height: 30px;
    float: right;
}

.game-modal-content .game_image{
    max-height: 40vh;
    min-height: 45vh;
    background-repeat: no-repeat;
    margin-top: 20px;
    background-position: left center;
    background-size: 350px;
}

.modal_image img{
    max-height: 100%;
}

@media only screen and (min-width: 720px) {

    .card{
        max-width: 48%;
        min-width: 48%;
    }

    .modal_image{
        height: 40ch;
    }

}

@media only screen and (min-width: 1050px) {
    .card{
        max-width: 32%;
        min-width: 32%;
    }

    .game-modal{
        width: 70vw;
        height: 80vh;
    }

    .game-modal-content{
        padding: 48px;
    }

    .game-modal-content .game_image{
        max-height: 400px;
        min-height: 450px;
    }
      
}

@media only screen and (min-width: 1440px) {
    .card{
        max-width: 24%;
        min-width: 24%;
    }
}

@media only screen and (min-width: 1920px) {
    .card{
        max-width: 19%;
        min-width: 19%;
    }
}
