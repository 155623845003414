.menu-images, .button-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.menu-image{
    max-width: 100%;
    height: auto;
}

.button-wrapper{
    margin-top: 30px;
    padding-bottom: 10px;
}

.button-wrapper .banner-button{
    max-width: auto;
    width: auto;
}