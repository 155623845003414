body{
  background: #121212;
  color: #FFF;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.app{
  transition: opacity .3s ease;
}

.app.app--home{
  background-image: url('./images/topimg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

header{
  padding: 40px 80px;
  background: #121212;
}

.app.app--home header{
  background: transparent;
}

.app.app--home .content.home-banner{
  background: transparent;
}

.content{
  padding: 0 80px;
  width: 100%;
  box-sizing: border-box;
  background: #121212;
}

nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .navigation a, nav .navigation a:visited{
  display: inline-block;
  padding-right: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  text-decoration: none;
  transition: all .3s ease-in-out;
}

nav .navigation a.active, nav .navigation a:hover{
  font-weight: 900;
  color: #07C8B7;
  text-decoration: none;
}

nav .navigation a:after{
  content: ' ';
  display: block;
  height: 4px;
  width: calc(100% + 20px);
  margin-top: 4px;
  margin-left: -10px;
  background-color: #07C8B7;
  opacity: 0;
  transition: all .3s ease-in-out;
}

nav .navigation a.active:after, nav .navigation a:hover:after{
  opacity: 1;
}

nav .navigation a:last-child{
  padding-right: 0;
}

.logo{
  width: 296px;
}

.navigation-top-mobile{
  display: none;
}

.burger-menu, .close-menu{
  display: none;
}

.divider{
  width: 100%;
  height: 140px;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #121212;
}

.divider.divider--reverse{
  transform: rotate(180deg);
}

.divider.divider--green{
  height: 120px;
}

.about{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.about p{
  margin: 48px 0;
}

.about img{
  margin: 48px 0 48px 48px;
  max-width: 50%;
}

h1, h2, h3{
  font-weight: 700;
  color: #FFFFFF;
  text-shadow: 0px 4px 0px rgba(7, 200, 183, 0.5);
  margin: 0;
}

h1{
  font-size: 56px;
  line-height: 56px;
}

h2{
  font-size: 44px;
  line-height: 44px;
}

h3{
  font-size: 32px;
  line-height: 44px;
}

p{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-color: #121212;
  overflow: hidden;
}

.footer-content{
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  color: #07C8B7;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 0px 3px 0px rgba(74, 165, 157, 0.7);
}

.footer-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 48px 0;
}

.footer-icon{
  margin-right: 50px;
}

.footer-icon:last-child{
  margin-right: 0;
}

q{
  display: inline-block;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.07em;
  text-align: center;
  color: #EBD300;
  text-shadow: 0px 6px 0px rgba(74, 165, 157, 0.7);
  padding: 80px 0 60px 0;
  max-width: 900px;
}

.app.app--home .content.home-banner.home-quote{
  display: flex;
  min-height: 360px;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
}

.video-banner{
  height: 500px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  /* height: 500px; */
  bottom: 200px;
  z-index: -999;
  min-height: 100%;
}

.parallax-container{
  height: 500px;
  width: 100%;
}

.react-parallax-background-children{
  height: 800px;
  width: 100%;
}

.about.about--petanque .banner-button{
  text-align: center;
  width: 250px;
}

.content.wip{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.content.wip svg{
  width: 50%;
  height: auto;
  max-height: 40vh;
  animation: shake 3.5s infinite;
}



input, select{
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  border: none;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
}

input:focus, input:focus-visible, select:focus, select:focus-visible{
  border: none;
  outline: none;
}

.search-input{
  position: relative;
}

.search-input svg{
  position: absolute;
  top: 12px;
  left: 12px;
}

.search-input input{
  padding-left: 40px;
}

.dropdown-wrapper{
  display: flex;
  flex-flow: column;
  position: relative;
}

.dropdown{
  min-width: 150px;
  background: #fff;
  height: 48px;
  border-radius: 24px;
  position: relative;
  color: black;
  box-sizing: border-box;
  font-size: 16px;
  padding: 12px;
  padding-right: 50px;
  padding-left: 16px;
}

small.round{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  font-size: 15px;
  box-sizing: border-box;
  background-color: white;
  color: #07C8B7;
  border-radius: 20px;
}

.modal{
  position: fixed;
  background-color: transparent;
  width: 90vw;
  height: 90vh;
  overflow-y: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 9px 5px rgba(7, 200, 183, 0.5);
  -webkit-box-shadow: 0px 1px 9px 5px rgba(7, 200, 183, 0.5);
  -moz-box-shadow: 0px 1px 9px 5px rgba(7, 200, 183, 0.5);
}

.modal-overlay{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.dropdown.dropdown--multiple{
  min-width: 220px;
}

img{
  max-width: 100%;
  height: auto;
}

.dropdown.dropdown--open, .dropdown--value{
  background: #07C8B7;
  color: #fff;
}

.dropdown .arrow{
  position: absolute;
  right: 12px;
  top: 4px;
  fill: black;
}

.dropdown.dropdown--open .arrow{
  transform: rotateX(180deg);
  fill: white;
}

.dropdown.dropdown--value .arrow{
  fill: white;
}

.dropdown-options{
  display: none;
  background: #FFF;
  color: black;
  margin-top: 10px;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  top: 48px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-height: 300px;
  overflow-y: auto;
}
.dropdown-options .option{
  padding: 10px;
}

.dropdown-options .option input{
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.dropdown-options--multiple .option{
  display: inline-flex;
}

.dropdown-options .option:first-child{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dropdown-options .option:last-child{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dropdown-options .option:hover, .dropdown-options .option.option--selected{
  color: black;
  background: #EBD300;
}

.dropdown-options--multiple .option:hover, .dropdown-options--multiple .option.option--selected{
  background: none;
}

.dropdown-options--open{
  display: block;
}

button, .slot{
  cursor: pointer;
}

input{
  cursor: text;
}

@media screen and (max-width: 1200px) {

  header{
    padding: 40px 40px;
  }
  .content{
    padding: 0 40px;
  }
}

.map-component{

  padding: 50px 0 30px 0;
  background: #121212;

}

.marker-details{
  width: 200px;
  height: 80px;
  background: rgba(35, 176, 162, 0.4);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 13px;
  line-height: 18px;
  transform: translate(-100px, -150px);
}


@media screen and (max-width: 959px) {
  .burger-menu {
    display: block;
  }
  .navigation{
    display: none;
  }

  .navigation.navigation--mobile-open .close-menu{
    display: block;
  }

  .navigation.navigation--mobile-open{
    position: fixed;
    display: flex;
    flex-flow: column;
    background: #121212;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    align-items: flex-start;
    padding: 0 40px;
    box-sizing: border-box;
  }

  .navigation.navigation.navigation--mobile-open .navigation-top-mobile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .navigation.navigation--mobile-open a{
    padding: 24px 0;
  }

  .about{
    flex-flow: column-reverse;
  }

  .about img{
    margin: 0 0 20px 0;
    max-width: 100%;
  }

  .about p{
    margin: 24px 0;
  }

  .about.about--petanque img{
    margin: 48px 0 0 0;
  }

  .app.app--home{
    background-image: url('./images/topimg-mobile2.jpg');
  }

}

@media only screen and (max-width: 600px) {

  .video-banner, .parallax-container{
    height: 250px;
  }

  .logo{
    width: 185px;
  }

  .footer-icons{
    margin-top: 0;
    margin-bottom: 16px;
  }

  .footer-icon{
    margin-right: 25px;
  }

  .footer-icon img{
    max-width: 32px;
  }

  .footer-content{
    font-size: 18px;
    max-width: 90%;
  }

  .app-header{
    padding: 24px;
  }

  .content{
    padding: 0 24px;
    overflow: hidden;
  }

  .content.wip svg{
    width: 100%;
    height: auto;
    max-height: auto;
  }

  .dropdown{
    min-width: 135px;
    font-size: 12px;
    line-height: 23px;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg);}
  10% { transform: translate(-1px, -2px) rotate(-1deg);}
  20% { transform: translate(-3px, 0px) rotate(1deg);}
  30% { transform: translate(3px, 2px) rotate(0deg);}
  40% { transform: translate(1px, -1px) rotate(1deg);}
  50% { transform: translate(-1px, 2px) rotate(-1deg);}
  60% { transform: translate(-3px, 1px) rotate(0deg);}
  70% { transform: translate(3px, 1px) rotate(-1deg);}
  80% { transform: translate(-1px, -1px) rotate(1deg);}
  90% { transform: translate(1px, 2px) rotate(0deg);}
  100% { transform: translate(1px, -2px) rotate(-1deg);}
}