.banner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.banner-content{
    max-width: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.banner-content p{
    font-size: 24px;
    line-height: 30px;
    margin: 32px 0;
    text-align: center;
}

.gameship{
    max-width: 100%;
}

.banner-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    background: #05A595;
    font-size: 20px;
    line-height: 16px;
    width: 200px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    text-decoration: none;
    transition: color .3s ease-in;
}
.banner-button:active, .banner-button:visited{
    color: #fff;
}

.banner-button:hover{
    color: #000;
}


.banner-button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
}


.banner-button:active:after {
    background: transparent;
}

.banner-button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #083f3a;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@media screen and (max-width: 959px) {
    .banner-content{
        max-width: 100%;
    }
  }

  @media screen and (max-width: 600px) {

    .banner-content p{
        margin-top: 12px;
        font-size: 20px;
      }

      .banner-content h1{
        font-size: 32px;
      }
  }
